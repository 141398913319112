<template>
  <div>
    <div class="mt-3">
      <form
        ref="form"
        @submit.stop.prevent="handleEdit"
      >
        <b-form-group
          label="Name(UZ)"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="management_type.name_uz"
            required
          />
        </b-form-group>
        <b-form-group
          label="Name(RU)"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="management_type.name_ru"
            required
          />
        </b-form-group>
        <b-form-group
          label="Name(EN)"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="management_type.name_en"
            required
          />
        </b-form-group>
      </form>

      <div class="text-right mt-3">
        <b-button
          variant="danger"
          class="mr-2"
          @click="closeDirectoryEdit"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="success"
          @click="handleEdit"
        >
          {{ $t('Edit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { editManagementTypes, showManagementType } from '@/api/directories'

export default {
  name: 'DirectoryEdit',
  props: {
    management_types: {
      default: null,
    },
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      management_type: {
        name_uz: '',
        name_ru: '',
        name_en: '',
      },
    }
  },
  watch: {
    management_types: {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchType()
        }
      },
    },
  },
  methods: {
    handleEdit() {
      if (this.management_type.name_uz
          && this.management_type.name_ru
          && this.management_type.name_en) {
        // eslint-disable-next-line no-unused-vars
        editManagementTypes(this.management_type).then(res => {
          this.$emit('editManagementTypes')
        })
      }
    },
    closeDirectoryEdit() {
      this.$emit('closeDirectoryEdit')
    },
    fetchType() {
      showManagementType(this.management_types.id).then(res => {
        this.management_type = res.data.data;
      })
    }
  },
}
</script>

<style scoped>

</style>
