<template>
  <div>
    <b-row>
      <b-col md="7">
        <DirectoryModal @addManagementTypes="managementTypesAdded" />
      </b-col>
      <b-col md="5">
        <div class="input-group mb-3">
          <input
            id="example-search-input"
            class="form-control py-2 border-right-0 border"
            type="search"
            :placeholder="$t('Search')"
            v-model="search"
          >
          <span class="input-group-append">
            <b-button variant="primary" @click="fetchManagementTypes">
              <b-icon icon="search" />
            </b-button>
          </span>
        </div>

      </b-col>
      <div class="w-100">
       <b-card>
         <b-table
             striped
             hover
             :items="management_types"
             :fields="fields"
             responsive=""
         >
           <template #cell(id)="data">
             <span class="text-md text-primary"> {{ data.index + 1 }}</span>
           </template>
           <template #cell(action)="data">
             <b-button
                 size="sm"
                 class="mr-1"
                 variant="primary"
                 @click="handlePush(data.item.id)"
             >
               <b-icon
                   icon="eye"
               />
             </b-button>
             <b-button
                 size="sm"
                 variant="warning"
                 @click="handleEdit(data.item)"
             >
               <b-icon
                   icon="pencil"
               />
             </b-button>
             <b-button
                 class="ml-1"
                 size="sm"
                 variant="danger"
                 @click="handleDelete(data.item.id)"
             >
               <b-icon
                   icon="trash"
               />
             </b-button>
           </template>
         </b-table>
       </b-card>
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          v-model="modalActive"
          title="Add Directory"
          hide-footer
        >
          <DirectoryEdit
            :management_types="activeManagementTypes"
            @closeDirectoryEdit="closeManagementTypes"
            @editManagementTypes="managementTypesEdited"
          />
        </b-modal>
      </div>
    </b-row>
  </div>
</template>

<script>

import { getManagementTypes, deleteManagementTypes } from '@/api/directories'
// eslint-disable-next-line import/extensions
import DirectoryModal from '@/views/loginpages/moderator/components/mnotypes/directory/DirectoryModal'
// eslint-disable-next-line import/extensions
import DirectoryEdit from '@/views/loginpages/moderator/components/mnotypes/directory/DirectoryEdit'

export default {
  name: 'Directory',
  components: {
    DirectoryEdit,
    DirectoryModal,
  },
  data() {
    return {
      management_types: [],
      modalActive: false,
      activeManagementTypes: null,
      search: '',
      fields: [
        {
          key: 'id',
          label: this.$t('Id'),
        },
        {
          key: 'name',
          label: this.$t('Name'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  created() {
    this.fetchManagementTypes()
  },
  methods: {
    fetchManagementTypes() {
      getManagementTypes({ search: this.search })
        .then(response => {
          this.management_types = response.data.data
        })
    },
    handleEdit(data) {
      // eslint-disable-next-line no-undef
      this.activeManagementTypes = _.cloneDeep(data)
      this.modalActive = true
    },
    managementTypesEdited() {
      this.modalActive = false
      this.fetchManagementTypes()
    },
    managementTypesAdded() {
      this.fetchManagementTypes()
    },
    closeManagementTypes() {
      this.modalActive = false
    },
    handleDelete(id) {
      if (id) {
        this.$swal({
          title: this.$t('areYouSure'),
          text: this.$t('wontReturn'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('YesDelete'),
          cancelButtonText: this.$t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // eslint-disable-next-line no-unused-vars
            deleteManagementTypes({ id }).then(res => {
              this.$swal({
                icon: 'success',
                title: this.$t('Deleted'),
                text: this.$t('dataHasDeleted'),
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchManagementTypes()
            })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: this.$t('Cancelled'),
              text: this.$t('yrDataSafe'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
      }
    },
    handlePush(id) {
      this.$router.push({ name: 'mnocentre', params: { id } })
    },
  },
}
</script>

<style scoped>
p{
  margin-left: 100px;
}
</style>
