<template>
  <div>
    <b-button
      class="bg-success"
      @click="modalActive=true"
    >
      {{ $t('Add') }}
    </b-button>
    <div class="mt-3">
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        v-model="modalActive"
        :title="$t('Add')"
        hide-footer
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            :label="$t('DirectoryNameUz')"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="management_types.name_uz"
              required
            />
          </b-form-group>
          <b-form-group
            :label="$t('DirectoryNameRu')"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="management_types.name_ru"
              required
            />
          </b-form-group>
          <b-form-group
            :label="$t('DirectoryNameEn')"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="management_types.name_en"
              required
            />
          </b-form-group>
        </form>

        <div class="text-right mt-3">
          <b-button
            variant="danger"
            class="mr-2"
            @click="modalActive = false"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
          >
            {{ $t('Add_Management') }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { addManagementTypes } from '@/api/directories'

export default {
  name: 'DirectoryModal',
  data() {
    return {
      modalActive: false,
      management_types: {
        name_uz: '',
        name_ru: '',
        name_en: '',

      },
    }
  },
  methods: {
    handleSubmit() {
      if (this.management_types.name_uz
          && this.management_types.name_ru
          && this.management_types.name_en) {
        // eslint-disable-next-line no-unused-vars
        addManagementTypes(this.management_types).then(res => {
          this.$emit('addManagementTypes')
          this.modalActive = false
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
